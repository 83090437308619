"use client";

import React, { useEffect } from "react";
import "./ProductPromotion.scss";

const ProductPromotion = () => {
    const handleHidePopup = () => {
        let selectInstallSlideover = document.querySelector(".jlt-install-slideover");
        selectInstallSlideover.setAttribute("style", "transform: translateX(-400px)");
        sessionStorage.setItem("jlt_spl_cls", true);
    };

    useEffect(() => {
        // wp-spotlight install slideover
        let selectInstallSlideover = document.querySelector(".jlt-install-slideover");

        window.addEventListener("scroll", () => {
            const isClose = sessionStorage.getItem("jlt_spl_cls");
            if (window.scrollY > 280) {
                if (!isClose) {
                    selectInstallSlideover.setAttribute("style", "transform: translateX(0px)");
                }
            } else {
                selectInstallSlideover.setAttribute("style", "transform: translateX(-400px)");
            }
        });
    }, []);

    return (
        <div className="jlt-install-slideover" style={{ transform: "translateX(-400px)" }}>
            <div className="jltwp-install-slideover-wrapper">
                <div className="jltwp-install-slideover-icon">
                    <img
                        width={48}
                        height={48}
                        src="https://wpspotlight.pro/mini-logo.svg"
                        alt="icon"
                    />
                </div>
                <div className="jltwp-install-slideover-content">
                    <p>
                        Navigate on your Dashboard faster with <strong> WP Spotlight!</strong>
                    </p>
                    <a target="_blank" href="https://wpspotlight.pro">
                        Try It Now
                    </a>
                </div>
                <button type="button" onClick={handleHidePopup}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default ProductPromotion;
